<template>
	<div id="group">
		<Datatable ref="datatable" v-bind:table="table" typeMessage="logMessage" v-on:resend="resendMsg"
			v-on:detail="detailMsg" />
		<v-dialog v-model="dialog.show" max-width="500">
			<v-card>
				<v-card-title style="background-color:#00E676;">
					<span class="text-h6 white--text">{{ this.dialog.title }}</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="12">
								<p> <span class="font-weight-bold pb-1">Receiver Number : </span>{{
										this.dialog.data.phonenumber
								}} <br>
									<span class="font-weight-bold pb-1">Date : </span>{{
											this.dialog.data.createdAt
									}} <br>
									<span class="font-weight-bold ob-1">Description :</span> <br> {{
											this.dialog.data.content
									}}
								</p>

								<p> </p>

							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-row align="center" justify="end" class="mb-5 mr-5">
						<v-btn @click="dialog.show = false" class="mr-2">
							Close
						</v-btn>

					</v-row>
				</v-card-actions>
			</v-card>
		</v-dialog>

	</div>
</template>

<script>
import Datatable from '@/components/Datatable'
import {
	get,
} from '@/service/axios.js'
import moment from 'moment'

export default {
	name: 'User',
	components: {
		Datatable
	},
	filters: {
		datetime: function (date) {
			console.log(date);
			return moment(date).format("DD MMM YYYY HH:mm")
		}
	},
	data() {
		return {
			dialog: {
				show: false,
				title: 'Message Detail',
				action: '',
				data: {},
			},
			table: {
				url: '/api/v2/message',
				keys: 'id',
				height: '100%',
				limit: 10,
				config: [
					{
						text: 'Action',
						class: 'text-center',
						action: true,
						value: [{
							icon: 'mdi-refresh',
							class: 'mr-2',
							color: 'warning',
							action: 'resend',

						},
						{
							icon: 'mdi-information',
							class: 'mr-2',
							color: 'primary',
							action: 'detail',
						},
						],
					},
					// {
					// 	text: 'Kode Pengirim',
					// 	class: '',
					// 	value: 'worker_session_name',
					// },
					{
						text: 'Receiver Number',
						value: 'phonenumber'
					},
					{
						text: 'Content',
						value: 'shortcontent',
					},
					{
						text: 'Message Status',
						value: 'status'
					},
					{
						text: 'Date',
						value: 'createdAt'
					},

				],
				filter: [
					// {
					// 	label: 'Kode Pengirim',
					// 	type: 'text',
					// 	value: 'worker_session_name'
					// },
					{
						label: 'Receiver Number',
						type: 'text',
						value: 'phonenumber'
					},
				]
			},
		}
	},

	computed: {
		message() {
			return this.$store.state.message.list
		},
	},
	mounted() {
		this.$store.dispatch('getListMessage')
	},

	methods: {
		detailMsg(value) {
			this.dialog.show = true
			get(`/api/v2/message/detail?id=${value}`)
				.then(response => {
					let res = response.data;

					if (res.success) {
						this.dialog.data = res.data
					}
				})
		},
		resendMsg(value) {
			this.$swal.fire({
				title: 'Resend this message?',
				icon: 'question',
				showCancelButton: true,
				confirmButtonColor: '#00E676',
				confirmButtonText: 'Send'
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('resendMsg', {
						id: value
					})
					this.$swal.fire({
						icon: 'success',
						title: 'Success send message!',
						showConfirmButton: false,
						timer: 1500
					}).then(() => {
						this.$refs.datatable.fetchDataTable();
					})
				}
			})
		}
	}

}
</script>