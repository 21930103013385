<template>
	<v-container fluid>
		<v-row class="text-right mb-3" justify="end">
			<!-- <v-btn class="white--text mx-1 btn-text" color="green accent-3">
				<v-icon>
					mdi-delete-outline
				</v-icon>
				<span> Delete </span>
			</v-btn> -->
			<v-btn @click="modal = true" class="white--text mx-1 btn-text" color="green accent-3">
				<v-icon>
					mdi-plus
				</v-icon>
				<span> ADD </span>
			</v-btn>
		</v-row>
		<v-col cols="12" class="d-none d-md-block">
			<v-simple-table class="white-row">
				<template v-slot:default>
					<thead class="">
						<tr>
							<!-- <th class="text-center" width="5%"><span class="" style="font-size: 15px;  font-weight: bold;"></span></th> -->
							<th class="text-left" width="10%"><span class="" style="font-size: 15px;  font-weight: bold;"></span></th>
							<th class="text-left" width="10%"><span class="" style="font-size: 15px;  font-weight: bold;">Label</span></th>
							<th class="text-left" width="10%"><span class="" style="font-size: 15px;  font-weight: bold;">Status</span></th>
							<th class="text-left" width="25%"><span class="" style="font-size: 15px;  font-weight: bold;">Token</span></th>
							<th class="text-right" width="20%"><span class="" style="font-size: 15px;  font-weight: bold;"></span></th>
						</tr>
					</thead>
					<tbody class="grey--text text--darken-3">
						<tr v-for="(sender, index) in worker" :key="index">
							<!-- <td>
								<v-checkbox></v-checkbox>
							</td> -->
							<td class="text-center py-3">
								<v-card height="100px" width="100px"
								class="pa-4 d-flex align-center justify-center rounded-lg"
								:color="sender.status == 'ready' ? 'green accent-3' : 'grey'">
								<v-icon size="60px" color="white">mdi-whatsapp</v-icon>
							</v-card>
						</td>
						<td class="text-left font-weight-bold">
							<div>
								{{ sender.label }}
							</div>
						</td>
						<td class="text-left">
							<div>
								{{ sender.status }}
							</div>
						</td>
						<td class="text-left">
							<div class="d-flex justify-center align-center">
								<v-text-field :value="sender.authToken" disabled class="mt-5"></v-text-field>
								<v-btn @click="copy(sender.authToken)" icon color="blue lighten-3">
									<v-icon>
										mdi-content-copy
									</v-icon>
								</v-btn>
							</div>
						</td>
						<td class="text-right">
							<v-row justify="end">
								<v-btn @click="scanQr(sender.authToken, index)" class="mx-1" color="green accent-3">
									<v-icon color="white">
										mdi-qrcode-scan
									</v-icon>
								</v-btn>
								<v-btn @click="resetWorker(sender.authToken, index)" class="mx-1"
								color="green accent-3">
								<v-icon color="white">
									mdi-restart
								</v-icon>
							</v-btn>
							<v-btn @click="deleteWorker(sender.id)" class="mx-1" color="red accent-3">
								<v-icon color="white">
									mdi-delete-outline
								</v-icon>
							</v-btn>
						</v-row>
					</td>
				</tr>
			</tbody>
		</template>
	</v-simple-table>
</v-col>
<v-col cols="12" class="d-block d-md-none">
	<v-card elevation="0" class="rounded-lg my-1" color="grey lighten-4" v-for="(sender, index) in worker"
	:key="index">
	<v-col>
					<!-- <div class="d-flex align-center justify-space-between mb-1">
						<span class="text--list-item grey--text">Phone Number</span>
						<h4 class="text--list-item font-weight-500">{{ sender.phonenumber }}</h4>
					</div> -->
					<div class="d-flex align-center justify-space-between mb-1">
						<span class="text--list-item grey--text">Label & Phone number</span>
						<h4 class="text--list-item font-weight-500">{{ sender.label }}</h4>
					</div>
					<div class="d-flex align-center justify-space-between mb-1">
						<span class="text--list-item grey--text">Status</span>
						<h4 class="text--list-item font-weight-500">{{ sender.status }}</h4>
					</div>
					<div class="d-flex align-center justify-space-between mb-1">
						<span class="text--list-item grey--text" style="width:50%;">Token</span>
						<v-text-field :value="sender.authToken" disabled></v-text-field>

						<!-- <v-btn v-else dense text outlined color="orange" rounded class="btn-text">
							<span>
								Generate
							</span>
						</v-btn> -->
					</div>
					<div class="d-flex align-center justify-end mb-1">
						<v-btn @click="scanQr(sender.authToken, index)" class="mx-1" color="green accent-3">
							<v-icon color="white">
								mdi-qrcode-scan
							</v-icon>
						</v-btn>
						<v-btn @click="resetWorker(sender.authToken, index)" class="mx-1" color="green accent-3">
							<v-icon color="white">
								mdi-restart
							</v-icon>
						</v-btn>
						<v-btn @click="deleteWorker(sender.id)" class="mx-1" color="red accent-3">
							<v-icon color="white">
								mdi-delete-outline
							</v-icon>
						</v-btn>
					</div>
				</v-col>
			</v-card>
		</v-col>
		<v-row class="pt-5" dense v-if="false">
			<v-col cols='12' xs="12" sm="6" md="3" lg="3" v-for="item in worker" :key="item.worker_id">
				<v-hover>
					<template v-slot:default="{ hover }">
						<v-card class="mx-auto px-auto text-center" max-width="344">
							<img class="qr-image mt-5">

							<v-card-text>
								<h4>
									{{ item.worker_session_name }}
								</h4>
								<h1 class="text-h6 green--text">
									{{ item.worker_phonenumber }}
								</h1>
							</v-card-text>

							<v-fade-transition>
								<v-overlay v-if="hover" absolute color="#036358">
									<v-btn class="green accent-3" fab small>
										<v-icon>mdi-cached</v-icon>
									</v-btn>
									<v-btn color="warning" fab small>
										<v-icon>
											mdi-account-remove-outline
										</v-icon>
									</v-btn>
									<v-btn color="error" fab small class="mx-3">
										<v-icon>
											mdi-delete
										</v-icon>
									</v-btn>
								</v-overlay>
							</v-fade-transition>
						</v-card>
					</template>
				</v-hover>
			</v-col>

			<v-col align="center" justify="center">
				<div v-if="noData">
					<v-img src="@/assets/no-data-ilus.svg" max-width="400">
					</v-img>
				</div>
			</v-col>
		</v-row>
		<!-- <v-overlay :value="overlay">

		</v-overlay> -->
		<v-dialog transition="dialog-top-transition" content-class="right-dialog" max-width="600" v-model="modalQr">
			<v-card :class="$vuetify.breakpoint.name !== 'xs' ? 'right-dialog text-center' : 'text-center'">
				<v-toolbar color="green accent-3" dark class="mb-5">Generate QR</v-toolbar>
				<img :src="qrIndex >= 0 ? worker[qrIndex].qrimage : ''" style="max-width: 100%; height: 300px" />
				<div>
					Please wait for qr to appears, after qr appears you may have to scanned it.
				</div>

				<v-card-actions class="justify-end pb-5">
					<v-btn outlined text @click="closeQR(worker[qrIndex].authToken, worker[qrIndex].status)">Close
					</v-btn>
					<v-btn @click="displayQR(qrIndex)" color="green accent-3" v-if="qrIndex >= 0"
						:disabled="worker[qrIndex].status == 'qr' || worker[qrIndex].status == 'ready'"
						:dark="worker[qrIndex].status == 'create' || worker[qrIndex].status == 'disconnect'">
						<v-icon class="pr-2">mdi-reload</v-icon>
						<span>Reload</span>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog max-width="600" v-model="modal">
			<v-card>
				<v-toolbar color="green accent-3" dark class="mb-5">Add Sender</v-toolbar>
				<v-card-text>
					<v-row class="mt-5">
						<v-col>
							<v-text-field dense type="text" color="green accent-3" label="Label"
							placeholder="Please type your label or phone number." outlined clearable
							v-model="form.label">
						</v-text-field>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions class="justify-end pb-5">
				<v-btn @click="modal = false" outlined text>Close</v-btn>
				<v-btn @click="save()" color="green accent-3" dark>
					<span>Save</span>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</v-container>
</template>


<script>
	import { saveToken } from '../service/token';
	import {
		get,
	} from '@/service/axios.js'

	export default {
		name: 'Sender',
		data() {
			return {
				isConnected: false,
				right: null,
				btnQRDisabled: true,
				modal: false,
				modalQr: false,
				overlay: false,
				noData: false,
				qrIndex: -1,
			}
		},
		filters: {
			truncate: function (source, size) {
				return source.length > size ? source.slice(0, size - 1) + "…" : source;;
			}
		},
		sockets: {
			updated: function (data) {
				this.$store.dispatch('getListWorker', data)
			}
		},

		computed: {
			validation() {
				return this.$store.state.validation.data
			},
			worker() {
				return this.$store.state.worker.list
			},

			form() {
				return this.$store.state.worker.form
			},

			user() {
				return this.$store.state.user.user
			},
		},

		methods: {
			copy(authToken) {
				navigator.clipboard.writeText(authToken);
			},
			scanQr(authToken, index) {
				this.qrIndex = index;
				this.modalQr = true;
				saveToken(authToken)
				this.$store.dispatch('displayWorker')
			},
			resetWorker(authToken, index) {
				this.$swal.fire({
					title: 'Are you sure you want to reset?',
					icon: 'question',
					showCancelButton: true,
					confirmButtonColor: '#00E676',
					confirmButtonText: 'Yes'
				}).then((result) => {
					if (result.isConfirmed) {
						saveToken(authToken)
						this.$store.dispatch('closeQR');
					}
				})
			},
			displayQR(index) {
				this.worker[index].status = 'qr'
				this.$store.dispatch('displayQR');
			},
			closeQR(authToken, status) {
				this.modalQr = false;
				if (status !== 'ready') {
					saveToken(authToken)
					this.$store.dispatch('closeQR');
					this.$store.dispatch('displayWorker')
				}
			},
			deleteWorker(id) {
				this.$swal.fire({
					title: 'Are you sure?',
					text: `This process can't be cancelled!`,
					icon: 'question',
					showCancelButton: true,
					confirmButtonColor: '#d33',
					confirmButtonText: 'Yes, delete!'
				}).then(async (result) => {
					if (result.isConfirmed) {
						await this.$store.dispatch('deleteWorker', { id });
						this.$store.dispatch('closeQR');
						this.$store.dispatch('displayWorker')
					}
				})
			},
			save() {
				this.modal = false;
				this.$swal.fire({
					title: 'Are you sure?',
					html: "Do you want to save them.<br>Your changes will be lost if you close this tab without saving.",
					icon: 'question',
					showCancelButton: true,
					confirmButtonColor: 'rgb(0, 230, 118)',
					confirmButtonText: 'Yes'
				}).then(async (result) => {
					if (result.isConfirmed) {
						let update = await this.$store.dispatch('addWorker', this.form);

						if (update) {
							this.$swal.fire({
								icon: 'success',
								title: 'Success adding sender!',
								showConfirmButton: false,
								timer: 1500
							}).then(() => {
								this.$store.dispatch('displayWorker')
							})
						} else {
							this.modal = true;
						}

					} else {
						this.modal = true;
					}
				})
			}
		},
		mounted() {
		// this.$store.dispatch("getProfile");
		// this.$socket.client.emit("join", "room_" + this.user.id);
		// this.$store.dispatch('displayWorker')
	},
	created() {
		get('/api/v2/user/profile').then(response => {
			let data = response.data.data;
			if (response.data.success) {
				setTimeout(() => {
					this.$socket.client.emit("join", "room_" + data.id);
					this.$store.dispatch('displayWorker')
				}, 200)
			}
		})
	},

}
</script>

<style scoped>
	.v-application--is-ltr .v-list-item__avatar:first-child {
		margin-right: 0px;
	}

	ul.v-breadcrumbs li a {
		color: green !important;
	}

	.btn-text {
		text-transform: unset !important;
	}

	.right-dialog {
		position: fixed !important;
		/* justify-content: end; */
		align-content: center;
		right: 0 !important;
		/* bottom: 0; */
		top: 0;
		max-width: 600px;
		margin-right: 3rem;
		margin-bottom: 3rem;
		margin-top: 3rem;
	}

	.text--list-item {
		font-size: 13px;
	}
</style>
