<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-card elevation="2" outlined class="mx-auto" color="rgb(241 251 244)"
                    style="border: 1px solid rgb(63 230 117)" max-height="200">
                    <v-list-item three-line>
                        <v-list-item-content>
                            <div class="text-overline mb-4 " style="color: rgb(63 230 117);">
                                <b>Note</b>
                            </div>
                            <v-list-item-subtitle class="pb-2 ">Please make your sender is ready.
                            </v-list-item-subtitle>
                            <v-list-item-subtitle class="pb-3">If sender are not ready then your message, goes
                                into message history. then after your sender are ready, Feel free to resend
                                message again.</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
            </v-col>
            <v-col class="d-flex justify-center">
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete @change="saveCurrentToken()" v-model="form.token" class="rounded-lg"
                            :items="worker" item-value="authToken" item-text="label" label="Select Sender" dense
                            outlined color="rgb(63 230 117)" hide-details="auto">
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                        <v-select class="rounded-lg" :items="contact" item-value="phonenumber" item-text="name"
                            label="Select Contact" :error-messages="validation.phonenumber" v-model="form.phonenumber"
                            dense outlined color="rgb(63 230 117)" hide-details="auto">
                        </v-select>
                    </v-col>
                    <v-col cols="12" v-if="false">
                        <v-text-field class="rounded-lg" label="Url File / IMG" dense outlined color="rgb(63 230 117)"
                            @click='pickFile'></v-text-field>
                        <input type="file" style="display: none" ref="image" accept="image/*" @change="onFilePicked">
                    </v-col>
                    <v-col cols="12">
                        <v-textarea class="rounded-lg" label="Message" dense outlined color="rgb(63 230 117)" rows="3"
                            :error-messages="validation.content" row-height="25" hide-details="auto"
                            v-model="form.content"></v-textarea>
                    </v-col>
                    <v-col>
                        <v-row>
                            <!-- <v-col cols="12" xs="6" sm="6" md="3">
                                <v-btn elevation="2" class="rounded-lg pa-5" block color="warning">Template</v-btn>
                            </v-col> -->
                            <v-col cols="12" xs="12" sm="12" md="12">
                                <v-btn elevation="2" class="rounded-lg pa-5" color="rgb(63 230 117)" block dark
                                    @click="sendMsg()">Kirim
                                </v-btn>
                            </v-col>
                            <!-- <v-col>
                                <p style="color: grey">Jika akan mengirim gambar atau file, silahkan isi URL file /
                                    Image Link gambar atau file harus disertakan dengan ekstensinya.<br>Contoh <i
                                        style="color: orange">https://picsum.photos/id/995/200/300.jpg</i></p>
                                <p style="color: rgb(63 230 117)"><b>Jenis Ekstensi file yang dapat dikirim dalam
                                        format:</b><br><i style="color: orange">.doc .pdf .xis .xlsx .csv .gif .jpg .png
                                        .mp4 .mp3</i></p>
                            </v-col> -->
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { saveToken } from '../service/token'

export default {
    name: 'User',
    data() {
        return {}
    },
    computed: {
        worker() {
            return this.$store.state.worker.option
        },

        form() {
            return this.$store.state.message.form
        },

        user() {
            return this.$store.state.user.user
        },

        contact() {
            return this.$store.state.contact.option
        },

        validation() {
            return this.$store.state.validation.data
        }
    },

    methods: {
        resetValidation() {
            this.$store.state.validation = {
                success: true,
                message: '',
                error_code: '',
                data: {}
            }
        },
        async sendMsg() {
            this.$swal.fire({
                title: 'Are you sure?',
                html: "Do you want to save them.<br>Your changes will be lost if you close this tab without saving.",
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: 'rgb(0, 230, 118)',
                confirmButtonText: 'Yes'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let success = await this.$store.dispatch('sendMessage', this.form)

                    if (success) {
                        this.$swal.fire({
                            icon: 'success',
                            title: 'Success sending a message!.',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.resetValidation();
                    } else {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Failed sending a message!.',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                } else {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Failed sending a message!.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })

        },

        saveCurrentToken() {
            if (this.form.token) {
                saveToken(this.form.token)
            }
        }

        // pickFile() {
        //     this.$refs.image.click()
        // },

        // onFilePicked(e) {
        //     const files = e.target.files
        //     if (files[0] !== undefined) {
        //         this.imageName = files[0].name
        //         if (this.imageName.lastIndexOf('.') <= 0) {
        //             return
        //         }
        //         const fr = new FileReader()
        //         fr.readAsDataURL(files[0])
        //         fr.addEventListener('load', () => {

        //             this.$store.state.outbox.form.outbox_media = files[0]
        //         })
        //     }
        // }
    },
    async created() {
        this.$store.dispatch("getOptionWorker");
        this.$store.dispatch("getOptionContact");
        console.log(this.worker);
    }
}
</script>