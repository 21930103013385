<template>
	<v-row>
		<v-col class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
			<v-card class="pa-5 rounded-xl">
				<div class="text-center">
					<img :src="user.image" class="grey rounded-circle lighten-2" style="width: 100%; max-width: 165px;">
					</img>
					<button :loading="isSelecting" @click="handleFileImport" class="icon fab ml-n8">
						<v-icon medium>
							mdi-camera
						</v-icon>
					</button>
					<input ref="uploader" class="d-none" type="file" @change="onFileChanged">
				</div>
				<div class="mt-5 text-body-1 text-center  font-weight-600">{{ user.name }}</div>
				<div class="font-size-11 text-center primary--text font-weight-600">{{ user.phonenumber }}</div>
			</v-card>
		</v-col>
		<v-col class="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9">
			<v-card class="pa-5 rounded-xl">
				<v-form ref="form" v-model="valid" lazy-validation>
					<v-container>
						<v-row align="center" justify="center">
							<v-col cols="12" sm="6" class="py-0">
								<v-text-field v-model="user.name" :rules="nameRules" class="green--text"
									label="Full Name">
								</v-text-field>
							</v-col>
							<v-col cols="12" sm="6" class="py-0">
								<v-text-field :error-messages="validation.email" v-model="user.email"
									:rules="emailRules" color="black" class="green--text" label="Email">
								</v-text-field>
							</v-col>
							<v-col cols="12" sm="6" class="py-0">
								<v-text-field :error-messages="validation.phonenumber" type="number"
									v-model="user.phonenumber" :rules="mobilePhoneRules" color="black"
									class="green--text" label="Phone Number">
								</v-text-field>
							</v-col>
							<v-col cols="12" sm="6" class="py-0">
								<v-text-field v-model="user.birthPlace" :rules="birthPlaceRules" color="black"
									class="green--text" label="Birth Place">
								</v-text-field>
							</v-col>
							<v-col cols="12" sm="12" class="py-0">
								<v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
									transition="scale-transition" offset-y min-width="auto">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="user.birthDate" label="Birth Date"
											append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
											:rules="birthDateRules">
										</v-text-field>
									</template>
									<v-date-picker v-model="user.birthDate" @input="menu2 = false" color="green">
									</v-date-picker>
								</v-menu>
							</v-col>

							<v-col cols="12" sm="12" class="py-0">
								<v-textarea rows="4" v-model="user.address" color="black" class="green--text"
									label="Address">
								</v-textarea>
							</v-col>
							<v-col cols="12" sm="12" class="py-0">
								<v-btn class="pa-2 white--text" color="green" rounded @click="updateProfile()">
									Update
								</v-btn>
							</v-col>
						</v-row>
					</v-container>
				</v-form>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
export default {
	data() {
		return {
			menu2: false,
			valid: true,
			isSelecting: false,
			selectedFile: null,
			nameRules: [
				v => !!v || 'Full Name cannot be empty',
			],

			userNameRules: [
				v => !!v || 'Username cannot be empty',
				v => (v.split(' ').length <= 1) || 'Username cannot contain spaces'
			],

			mobilePhoneRules: [
				v => !!v || 'Phone Number cannot be empty',
			],

			emailRules: [
				v => !!v || 'Email cannot be empty',
				v => /.+@.+\..+/.test(v) || 'Email not valid',
			],

			birthPlaceRules: [
				v => !!v || 'Birth Place cannot be empty'
			],

			birthDateRules: [
				v => !!v || 'Birth Date cannot be empty'
			],

			showPass: false,
			passwordRules: [
				v => !!v || 'Password cannot be empty',
				v => v.length >= 5 || 'Password length min 5 character',
				v => v.match(/\d+/g) || 'Password must contain letters'
			],
		}
	},

	computed: {
		validation() {
			return this.$store.state.validation.data
		},
		user() {
			return this.$store.state.user.user
		},

	},

	mounted() {
		this.$store.state.validation = {
			success: true,
			message: '',
			error_code: '',
			data: {}
		}
	},

	methods: {
		handleFileImport() {
			this.isSelecting = true;

			window.addEventListener('focus', () => {
				this.isSelecting = false
			}, { once: true });

			this.$refs.uploader.click();


		},
		onFileChanged(e) {
			let fd = new FormData()

			fd.append('image', e.target.files[0])

			let update = this.$store.dispatch("updateImageProfile", fd);

			if (update) {
				this.$swal.fire({
					icon: 'success',
					title: 'Success updating profile!!',
					showConfirmButton: false,
					timer: 1500
				}).then(() => {
					this.$refs.datatable.fetchDataTable();
				})
			}
		},
		updateProfile() {
			this.$swal.fire({
				title: 'Update Profile',
				text: "Are you sure want to update this profile?",
				icon: 'question',
				showCancelButton: true,
				confirmButtonColor: 'rgb(0, 230, 118)',
				confirmButtonText: 'Yes'
			}).then(async (result) => {
				if (result.isConfirmed) {
					let update = await this.$store.dispatch("updateProfile", this.user)

					if (update) {
						this.$swal.fire({
							icon: 'success',
							title: 'Success updating profile!!',
							showConfirmButton: false,
							timer: 1500
						}).then(() => {
							this.$refs.datatable.fetchDataTable();
						})
					}
				}
			})
		},
	}
}
</script>